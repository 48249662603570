<template>
  <v-snackbar
    :color="snackbar.color"
    :timeout="3000"
    bottom
    center    
    v-model="snackbar.show"
  >
    {{ snackbar.text }}
    <v-btn dark  @click.native="snackbar.show = false" icon> 
      <v-icon>close</v-icon>
    </v-btn>
  </v-snackbar>   
</template>

<script>
export default {
  name: 'app-snackbar',
  data() {
    return {            
      snackbar: {
          show : false,
          text : '',
          // color: 'success',
      }               
    }
  },  
  methods: {
    
  },
  created () {
    window.getApp.$on('showSnackbar', (snackbar) => {
        Object.assign(this.snackbar, snackbar);      
    });
  },
};
</script>
<style>
.v-snack {
  font-family: 'Roboto', sans-serif;
}
</style>