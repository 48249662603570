<template>
  <v-app>
  <!-- <div id="app" class="app"> -->
    <router-view/>
    <app-snackbar></app-snackbar> 
  <!-- </div> -->
</v-app>
</template>

<script>
import AppEvents   from '@/app/event'
import AppSnackbar from '@/app/components/admin/app/Snackbar'
import User        from '@/utils/models/User'
export default {
  name: 'app',
  components: {AppSnackbar},
  created () {
    window.getApp = this;
    
    AppEvents.forEach(item => {
        this.$on(item.name, item.callback);
    });                

    if(User.isLogin()){            
      // this.enableNotifications();      
    } else {
      this.$router.replace({ path: '/login' });
    }

    this.$store.dispatch('GET_DICTIONS');
  }, 
  mounted(){
    // setTimeout(()=>{console.log(this.$store.getters.DICTIONS);}, 3000)    
  },
  watch:{
    $route (to/*, from*/){
      if(!to.meta.guest){
        User.isLogin();
      }                     
    },
  }    
}
</script>
<style>
.swal2-container {font-family: 'Roboto', sans-serif;}
.ramping td, .ramping th {padding: 0 5px;}
input[type='date']{
  width:145px !important;
}
</style>
