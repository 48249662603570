import Vue from 'vue'
import Router from 'vue-router'
import AppRouter    from '@/app/router'

Vue.use(Router)

/*const EmptyParentComponent = {
  template: '<router-view></router-view>',
}*/

const demoRoutes = []
/*if (process.env.NODE_ENV === 'development' || process.env.VUE_APP_INCLUDE_DEMOS) {
  const vueBookRoutes = require('./vueBookRoutes').default
  vueBookRoutes.forEach(route => demoRoutes.push(route))
}*/

export default new Router({
  mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  routes: [
    ...demoRoutes, ...AppRouter,    
  ],
})
