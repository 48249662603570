import Vue from 'vue'
import App from './App.vue'
import Config from './config'
import vuetify from '@/plugins/vuetify'
import store from '@/store/index'
import router from '@/router/index'
import AsyncComputed from 'vue-async-computed'
import VueSweetalert2 from 'vue-sweetalert2'
import VueCurrencyFilter from 'vue-currency-filter'
import VCurrencyField from '@/plugins/VCurrencyField'
import Truncate from 'lodash.truncate'
import Moment from 'moment'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import '@/registerServiceWorker'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import 'sweetalert2/dist/sweetalert2.min.css'

Vue.config.productionTip = false
Vue.filter('truncate', Truncate);
Vue.config.productionTip = false;
Vue.use(VueSweetalert2);
Vue.use(AsyncComputed);
Vue.component('v-currency-field', VCurrencyField);
Vue.use(VueCurrencyFilter, {
  symbol : '', thousandsSeparator: '.', fractionCount: 0, fractionSeparator: ',', symbolPosition: 'front', symbolSpacing: false
});
Vue.prototype.$config = Config;
Vue.prototype.$moment = Moment;

String.prototype.capitalize = function() {
  return this.charAt(0).toUpperCase() + this.slice(1);
}
 
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDR_pUl6SqElZpjWCNHDxCLSormeVkNwC0',
    libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // v: '3.26',
  },
 
  //// If you intend to programmatically custom event listener code
  //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
  //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
  //// you might need to turn this on.
  // autobindAllEvents: false,
 
  //// If you want to manually install components, e.g.
  //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
  //// Vue.component('GmapMarker', GmapMarker)
  //// then disable the following:
  // installComponents: true,
})
Vue.component(VueQrcode.name, VueQrcode);

router.beforeEach((to, from, next) => {
  NProgress.start()
  next()
})

router.afterEach((to, from) => {
  NProgress.done()
})


/* eslint-disable no-new */
new Vue({
  vuetify,
  el: '#app',
  router,
  store,
  render: h => h(App),
})