import Device from '@/utils/models/Device';
import User   from '@/utils/models/User';

export default [
  {
    name: 'APP_DEVICE_NOTLICENCE',
    callback: function () {
        Device.unset();
        this.$router.replace({ path: '/device-register' });
    }
  },      
  {
    name: 'APP_LOGIN_SUCCESS',
    callback: function () {        
       this.$router.push({ path: '/' });
    }
  },
  {
    name: 'APP_LOGOUT',
    callback: function () {
        User.unset();                
        if(!this.$route.meta.guest){
            this.$router.replace({ path: '/login' });
        }    
    }
  },
  {
    name: 'APP_PAGE_LOADED',
    callback: function () {
    }
  },
  {
    name: 'APP_AUTH_FAILED',
    callback: function () {
      this.$router.push('/login');
      this.$message.error('Token has expired');
    }
  },
  {
    name: 'APP_BAD_REQUEST',
    // @error api response data
    callback: function (msg) {
      this.$message.error(msg);
    }
  },
  {
    name: 'APP_ACCESS_DENIED',
    // @error api response data
    callback: function () {
      //this.$message.error(msg);
      this.$router.push('/deny').catch(()=>{});
      //this.$router.replace({ path: '/deny' });
    }
  },
  {
    name: 'APP_RESOURCE_DELETED',
    // @error api response data
    callback: function (msg) {
      this.$message.success(msg);
    }
  },
  {
    name: 'APP_RESOURCE_UPDATED',
    // @error api response data
    callback: function (msg) {
      this.$message.success(msg);
    }
  },
  
];
