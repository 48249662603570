import { api } from '@/utils/interface';

const state = {
  dictions: {},
}

const mutations = {
  SET_DICTIONS : (state,payload) => {
    state.dictions = payload
  },
}

const actions = {
  GET_DICTIONS : async (context/*,payload*/) => {
    let { data } = await api.get('diction')
    context.commit('SET_DICTIONS',data)
  },
}

const getters = {
  DICTIONS : state => {
    return state.dictions;
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
