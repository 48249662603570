export default [
  {
    //Router Administrator
    path: "/",
    component: () => import("@/app/components/admin/AppLayout.vue"),
    meta: { guest: false },
    children: [
      {
        path: "",
        redirect: { name: "dashboard" },
      },
      // test
      {
        name: "test",
        path: "test",
        component: () => import("@/app/views/test/index.vue"),
      },
      {
        name: "login",
        path: "login",
        redirect: { name: "signin" },
      },
      {
        name: "deny",
        path: "deny",
        redirect: { name: "403" },
      },
      {
        name: "dashboard",
        path: "dashboard",
        component: () => import("@/app/views/dashboard/index.vue"),
        default: true,
      },
      {
        name: "pegawai",
        path: "pegawai",
        component: () => import("@/app/views/pegawai/index.vue"),
      },
      {
        name: "pegawai/data",
        path: "pegawai/data",
        component: () => import("@/app/views/pegawai/index.vue"),
      },
      {
        name: "pegawai/rincian",
        path: "pegawai/rincian",
        component: () => import("@/app/views/pegawai/rincian.vue"),
      },
      {
        title: "Form Pegawai",
        name: "pegawai/form",
        path: "pegawai/form",
        component: () => import("@/app/views/pegawai/form.vue"),
      },
      {
        title: "View Pegawai",
        name: "pegawai/view",
        path: "pegawai/view",
        component: () => import("@/app/views/pegawai/view.vue"),
      },
      {
        title: "Editall Pegawai",
        name: "pegawai/editall",
        path: "pegawai/editall",
        component: () => import("@/app/views/pegawai/editall.vue"),
      },
      {
        title: "Daftar Riwayat",
        name: "kepegawaian",
        path: "kepegawaian",
        // children: [
        //   {
        //     title: "Jabatan",
        //     path: "jabatan",
        //     // name: "jabatan",
        // component: () => import("@/app/views/pegawai/riwayat.vue"),
        //   },
        // ],
      },
      // kepangkatan page-content="active" while solusion
      {
        path: "kepegawaian/jabatan",
        name: "kepegawaian/jabatan",
        component: () => import("@/app/views/pegawai/riwayat.vue"),
      },
      {
        path: "kepegawaian/kepangkatan",
        name: "kepegawaian/kepangkatan",
        component: () => import("@/app/views/pegawai/riwayat.vue"),
      },
      {
        path: "kepegawaian/penghargaan",
        name: "kepegawaian/penghargaan",
        component: () => import("@/app/views/pegawai/riwayat.vue"),
      },
      {
        path: "kepegawaian/hukuman",
        name: "kepegawaian/hukuman",
        component: () => import("@/app/views/pegawai/riwayat.vue"),
      },
      {
        path: "kepegawaian/diklat",
        name: "kepegawaian/diklat",
        component: () => import("@/app/views/pegawai/riwayat.vue"),
      },
      {
        path: "kepegawaian/penugasan",
        name: "kepegawaian/penugasan",
        component: () => import("@/app/views/pegawai/riwayat.vue"),
      },
      {
        path: "kepegawaian/mengajar",
        name: "kepegawaian/mengajar",
        component: () => import("@/app/views/pegawai/riwayat.vue"),
      },
      {
        path: "kepegawaian/seminar",
        name: "kepegawaian/seminar",
        component: () => import("@/app/views/pegawai/riwayat.vue"),
      },
      {
        path: "kepegawaian/cuti",
        name: "kepegawaian/cuti",
        component: () => import("@/app/views/pegawai/riwayat.vue"),
      },
      {
        path: "kepegawaian/pelatihan",
        name: "kepegawaian/pelatihan",
        component: () => import("@/app/views/pegawai/riwayat.vue"),
      },
      {
        path: "kepegawaian/sertifikasi",
        name: "kepegawaian/sertifikasi",
        component: () => import("@/app/views/pegawai/riwayat.vue"),
      },
      {
        name: "myprofile",
        path: "myprofile",
        component: () => import("@/app/views/myprofile/index.vue"),
      },
      {
        title: "Update Profile",
        name: "myprofile/form",
        path: "myprofile/form",
        component: () => import("@/app/views/myprofile/form.vue"),
      },
      {
        title: "Rapor Kinerja",
        // name: "myprofile/rapor-kinerja",
        name: "rapor-kinerja",
        path: "rapor-kinerja",
        component: () => import("@/app/views/myprofile/rapor-kinerja.vue"),
      },
      {
        title: "Periode",
        name: "master/periode",
        path: "master/periode",
        component: () => import("@/app/views/master/periode/index.vue"),
      },
      {
        title: "Indikator",
        name: "master/indikator",
        path: "master/indikator",
        component: () => import("@/app/views/master/indikator/index.vue"),
      },
      {
        name: "master/indikator/form",
        path: "master/indikator/form",
        component: () => import("@/app/views/master/indikator/form.vue"),
      },
      {
        title: "Nodes",
        name: "master/nodes",
        path: "master/nodes",
        component: () => import("@/app/views/master/nodes/index.vue"),
      },
      {
        name: "master/nodes/form",
        path: "master/nodes/form",
        component: () => import("@/app/views/master/nodes/form.vue"),
      },
      // {
      //   title: "Organisasi",
      //   name: "master/orgn",
      //   path: "master/orgn",
      //   component: () => import("@/app/views/master/orgn/index.vue"),
      // },
      {
        title: "Manage Organisasi",
        name: "master/organisasi",
        path: "master/organisasi",
        component: () => import("@/app/views/master/orgn/manage.vue"),
      },
      {
        name: "master/orgn/form",
        path: "master/orgn/form",
        component: () => import("@/app/views/master/orgn/form.vue"),
      },
      {
        title: "Parameters",
        name: "master/params",
        path: "master/params",
        component: () => import("@/app/views/master/params/index.vue"),
      },
      {
        title: "Parameters Form",
        name: "master/params/form",
        path: "master/params/form",
        component: () => import("@/app/views/master/params/form.vue"),
      },
      {
        title: "Manage Penilai",
        name: "penilaian/penilai",
        path: "penilaian/penilai",
        component: () => import("@/app/views/penilaian/kinerja/penilai.vue"),
      },
      {
        title: "Manage Rapor",
        name: "penilaian/kinerja",

        path: "penilaian/kinerja",
        component: () => import("@/app/views/penilaian/kinerja/index.vue"),
      },

      {
        title: "Input Rapor",
        name: "penilaian/list",
        path: "penilaian/list",
        component: () => import("@/app/views/penilaian/kinerja/list.vue"),
      },
      {
        name: "penilaian/list/form",
        path: "penilaian/list/form",
        component: () => import("@/app/views/penilaian/kinerja/form.vue"),
      },

      {
        title: "Daftar Urut Kepangkatan",
        name: "laporan/duk",
        path: "laporan/duk",
        component: () => import("@/app/views/laporan/duk.vue"),
      },
      {
        title: "Bezeting",
        name: "laporan/rekap",
        path: "laporan/rekap",
        component: () => import("@/app/views/laporan/rekap.vue"),
      },
      /*{
        name : 'pages/blank',
        path : 'pages/blank',
        component: () => import('@/app/views/pages/blank.vue'),          
    },
    {
        name : 'pages/helloworld',
        path : 'pages/helloworld',
        component: () => import('@/app/views/pages/helloworld.vue'),          
    },*/
      {
        name: "admin/users",
        path: "admin/users",
        component: () => import("@/app/views/admin/users/index.vue"),
      },
      {
        name: "admin/users/form",
        path: "admin/users/form",
        component: () => import("@/app/views/admin/users/form.vue"),
      },
      {
        name: "admin/rbac",
        path: "admin/rbac",
        component: () => import("@/app/views/admin/rbac/index.vue"),
      },
      {
        name: "profile",
        path: "profile",
        component: () => import("@/app/views/profile/index.vue"),
      },
      {
        title: "Update Profile",
        name: "profile/form",
        path: "profile/form",
        component: () => import("@/app/views/profile/form.vue"),
      },
      /*{
        path: '*',
        redirect: { name: '404' },
    },*/
    ],
  },
  {
    // Router untuk Guest
    path: "/guest/",
    component: () => import("@/app/components/auth/AuthLayout.vue"),
    children: [
      {
        name: "404",
        path: "404",
        meta: { guest: true },
        component: () => import("@/app/components/auth/page/404.vue"),
      },
      {
        name: "403",
        path: "403",
        meta: { guest: true },
        component: () => import("@/app/components/auth/page/403.vue"),
      },
      {
        name: "signin",
        path: "signin",
        meta: { guest: true },
        component: () => import("@/app/views/auth/login.vue"),
      },
      {
        name: "signup",
        path: "signup",
        meta: { guest: true },
        component: () => import("@/app/views/auth/signup.vue"),
      },
      /*{
        name: 'recover-password',
        path: 'recover-password',
        meta: {guest:true},
        component: () => import('@/app/components/auth/recover-password/RecoverPassword.vue'),
    },*/
      {
        path: "*",
        meta: { guest: true },
        redirect: { name: "signin" },
      },
      /*{
        path: '*',
        redirect: { name: '404' },
    },*/
    ],
  },
];