// let baseUrl = 'http://localhost:3000/';
let baseUrl = 'https://rest.simpeg.arrisalah.sch.id/';

export default {   
  rest    :  baseUrl + 'v01/',          
  title   :  'SIMPEG AR RISALAH',
  prefix  :  'arr',   
  theme   : {
    primary : 'blue',
    secondary : 'green',
    accent : 'orange'  
  }     
  /*file    :  function(route, id){
    return baseUrl + 'frontend/web/index.php/site/' + route + '?referer='+btoa(AndroidOrigin)+'&refd='+btoa(id);   
  }, */
}

