import Vue        from 'vue'
import Vuex       from 'vuex'
import VuexORM    from '@vuex-orm/core'
import Secure     from '@/utils/secure'
import Persisted  from 'vuex-persistedstate'
//import VuexI18n   from 'vuex-i18n' // load vuex i18n module

import app from './modules/app'
import diction from './modules/diction'

import * as getters from './getters'

let database = new VuexORM.Database();
//database.register(Notifications, {});

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: true, // process.env.NODE_ENV !== 'production',
  getters,
  modules: {
    app, diction
  },
  state: {},
  mutations: {},
  plugins: [
    VuexORM.install(database),
    Persisted({
      storage: {
        getItem: key => Secure.dec(window.localStorage.getItem(key)),          
        setItem: (key, value) =>            
              window.localStorage.setItem(key, Secure.enc(value)),
        removeItem: key => window.localStorage.removeItem(key)
      }
    })
  ]
})

//Vue.use(VuexI18n.plugin, store)

export default store
